import React, {useState} from 'react';
import {firebaseApp} from "../App";
import {User} from "@firebase/auth-types";
import {Redirect} from "react-router";

export default function Login({user}: { user: User | null }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  if (user) {
    return <Redirect to="/list"/>
  }
  return (
    <div style={{maxWidth: "600px", margin: "0 auto", padding: "1rem"}}>
      <h1 className="title">Login by Firebase</h1>
      <div className="field">
        <p className="control has-icons-left has-icons-right">
          <input className="input" type="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)}/>
          <span className="icon is-small is-left"><i className="fas fa-envelope"/></span>
        </p>
      </div>
      <div>
        <div className="field">
          <p className="control has-icons-left">
            <input className="input" type="password" placeholder="Password"
                   onChange={(event) => setPassword(event.target.value)}/>
            <span className="icon is-small is-left"><i className="fas fa-lock"/></span>
          </p>
        </div>
        <button className="button is-info is-large is-fullwidth" disabled={loading} onClick={() => login()} color="primary">
          Login
        </button>
      </div>
    </div>
  );

  async function login() {
    setLoading(true);
    try {
      await firebaseApp.auth!().signInWithEmailAndPassword(email, password);
    } catch (error) {
      alert(error.message)
    }
    setLoading(false);
  }
}

