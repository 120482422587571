import React, {useState, useEffect} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './App.css';
import Login from "./pages/Login";
import firebase from "firebase/app"
import 'firebase/auth'
import { User } from "@firebase/auth-types";
import UserPage from "./pages/UserPage";
import 'bulma/bulma.sass'
import quadLogo from './quad-logo.svg';
import appLogo from './zoneLogo.png';

const firebaseConfig = {
  apiKey: "AIzaSyC4E0-E1sIhBZCzft-sBIcaUWnTEfv3LYM",
  authDomain: "zsb-recruiting.firebaseapp.com",
  projectId: "zsb-recruiting",
  storageBucket: "zsb-recruiting.appspot.com",
  messagingSenderId: "298495443235",
  appId: "1:298495443235:web:1394a36d93205b76c24c96"
};

export const firebaseApp: any = firebase.initializeApp(firebaseConfig);

function App() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // NOTE:- Firebase Authenticationの状態を監視するコールバックを登録
    const subscription: () => void | undefined = registerAuthStateChangedHandler();
    return function cleanUp() {
      subscription && subscription();
    };
  }, []);

  return (
    <div className="App">
      {initialized ?
        <div>
          <nav className="navbar is-black" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <a className="navbar-item" href="/">
                <img src={ appLogo } alt="logo" />
                ZSB Recruiting
              </a>
              <span role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
                 data-target="navbarBasicExample">
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </span>
            </div>
            <div id="navbarBasicExample" className="navbar-menu">
              <div className="navbar-end">
                { user &&
                <span className="navbar-item is-link is-right" onClick={ () => signOut()}>
                  ログアウト
                </span>
                }

              </div>
            </div>
          </nav>
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Login user={user} />
              </Route>
              <Route path="/">
                {/* UserPage.tsx */}
                <UserPage user={user}/>
              </Route>
              <Redirect to="/list" />
            </Switch>
          </BrowserRouter>
        </div> :
        <div>Initializing Firebase...</div>
      }
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            powered by <img src={quadLogo} alt="quad logo" width={100} height={30} />
          </p>
        </div>
      </footer>
    </div>
  );

  async function signOut() {
    console.log("Signout Start");
    await firebaseApp.auth().signOut();
    console.log("Signout End");
  }

  function registerAuthStateChangedHandler() {
    return firebase.auth!().onAuthStateChanged(async (user: User | null) => {
      if(user) {
        setUser(user);
      } else {
        console.log("Not Login");
        setUser(null);
      }
      setInitialized(true);
    })
  }
}

export default App;
