/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {User} from "@firebase/auth-types";
import Loading from '../components/Loading';
import Comment from "../components/Comment";
import './EntryDetail.scss';
import Rating from '@material-ui/lab/Rating';
import {Button, TextField} from "@material-ui/core";
import evalImage from "./eval-image.png";

export default function EntryDetail({match, user}: { match: any, user: User | null }) {
  const {id} = match.params
  const [loading, setLoading] = useState<boolean>(false);
  const [entry, setEntry] = useState<any>(null);
  const [keyOp, setKeyOp] = useState<boolean>(true);
  // const [newStatus, setNewStatus] = useState<number>(0);

  const keyOpRef = useRef<boolean>();
  const location: any = useLocation();
  keyOpRef.current = keyOp;
  const entryRef = useRef<any>();
  entryRef.current = entry;
  const [images, setImages] = useState<any[]>([
    'https://placehold.jp/150x150.png?text=Loading',
    'https://placehold.jp/150x150.png?text=Loading',
    'https://placehold.jp/150x150.png?text=Loading'
  ]);
  const history = useHistory();

  // const statusText = new HashMap<number, string>([
  //   [1, "書類提出"],
  //   [2, "3次面接 - 日程調整中"],
  //   [3, "3次面接 - 日程確定"],
  //   [4, "最終面談"],
  //   [99, "NG"],
  // ]);

  useEffect(() => {
    if (user) {
      fetchDetail().then();
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      fetchDetail().then();
    }
  }, [id]);
  const keydownHandler = useCallback(async (e) => {
    if (keyOpRef.current) {
      if (document.getElementsByClassName('fancybox-container').length === 0) {
        if (e.keyCode === 37 && entryRef.current.navi.prev_id) {
          (location.state.entries) ?
            history.push(`/detail/${entryRef.current.navi.prev_id}`, {entries: location.state.entries}) :
            history.push(`/detail/${entryRef.current.navi.prev_id}`);
        }
        if (e.keyCode === 39 && entryRef.current.navi.next_id) {
          (location.state.entries) ?
            history.push(`/detail/${entryRef.current.navi.next_id}`, {entries: location.state.entries}) :
            history.push(`/detail/${entryRef.current.navi.next_id}`);
        }
      }
    }
  }, []);
  useEffect(() => {
    // console.log(entry);
    document.addEventListener("keydown", keydownHandler, false);
  }, []);
  const fetchDetail = async () => {
    setLoading(true);
    try {
      const {data} = await axios.get(`https://app.q-uad.me/api/entry/${id}.json`, {
        headers: {
          "x-access-token": "dc61e2ef7284eb2bf489ecfe92757cf251af87961d0f450ab98bd37619150866",
          "x-firebase-user-id": user?.uid
        }
      });
      setEntry(() => {
        if (location.state?.entries) {
          const idx = location.state.entries.findIndex((e: any) => e.id === id);
          data.result.entry.navi.next_id = (idx > 0) ? location.state.entries[idx - 1].id : null;
          data.result.entry.navi.prev_id = (idx < location.state.entries.length - 1) ? location.state.entries[idx + 1].id : null;
        }
        return data.result.entry;
      });
      images[0] = data.result.pictures.bust_up;
      images[1] = data.result.pictures.full_body;
      images[2] = data.result.pictures.picture;
      setImages(images);
      fancybox();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const getHyokakey = (email: string | null | undefined) => {
    if (email === "mynavi@zsb-recruiting.com") {
      return "mynavi";
    } else if (email === "hakuhodo@zsb-recruiting.com") {
      return "hakuhodo";
    } else if (email === "suntory@zsb-recruiting.com"){
      return "suntory";
    }
    return "";
  };

  const changeRating = async (value: number | null, index: number) => {
    const metaData = entryRef.current.meta_data ?? {
      "rating": {"mynavi": {"author":"" ,"point":[0, 0, 0, 0, 0]}, "hakuhodo": {"author":"" ,"point":[0, 0, 0, 0, 0]}},
      "comments": []
    };
    const key = getHyokakey(user?.email);
    if (key) {
      metaData.rating[key].point[index - 1] = value;
    }
    entryRef.current.meta_data = metaData;
    setEntry(Object.assign({}, entryRef.current));
    await putMetaData(entryRef.current.id, metaData);
  }

  const changeRatingAuthor = async (value: string|null) => {
    const metaData = entryRef.current.meta_data ?? {
      "rating": {"mynavi": {"author":"" ,"point":[0, 0, 0, 0, 0]}, "hakuhodo": {"author":"" ,"point":[0, 0, 0, 0, 0]}},
      "comments": []
    };
    const key = getHyokakey(user?.email);
    if (key) {
      metaData.rating[key].author = value;
    }
    entryRef.current.meta_data = metaData;
    setEntry(Object.assign({}, entryRef.current));
    await putMetaData(entryRef.current.id, metaData);
  }

  const putMetaData = async (id: string, metaData: any) => {
    try {
      await axios.put(`https://app.q-uad.me/api/meta/${id}.json`, {
          metaData
        },
        {
          headers: {
            "content-type": "application/json",
            "x-access-token": "dc61e2ef7284eb2bf489ecfe92757cf251af87961d0f450ab98bd37619150866",
            "x-firebase-user-id": user?.uid
          }
        })
    } catch (error) {
      console.error(error)
    } finally {
    }
  }
  const renderPaging = (entry: any) => {
    return (
      <>
        {entry &&
        <div className="paging" style={{padding: "10px 0"}}>
          {entry.navi.prev_id &&
          <button style={{margin: "0 5px"}} className="button is-small is-primary"
                  onClick={
                    () => {
                      history.push(`/detail/${entry.navi.prev_id}`, {entries: location.state?.entries})
                    }
                  }> 前の応募者 </button>
          }
          {entry.navi.next_id &&
          <button style={{margin: "0 5px"}} className="button is-small is-primary"
                  onClick={() => {
                    history.push(`/detail/${entry.navi.next_id}`, {entries: location.state?.entries})
                  }}
          > 次の応募者 </button>
          }
        </div>
        }
      </>
    )
  }
  return (
    <div className="container">
      {loading ? <Loading/> :
        <>
          {
            (["月1〜3日程度飲む",
              "それ以下の頻度で飲む",
              "過去に飲んだことはあるが今は飲んでいない",
              "飲んだことがない"].includes(entry?.entry_data.energy_drink)) &&
            <div className="notification is-danger is-dark">
              この応募者は飲用頻度が週1日以下です
            </div>
          }

          <div className="detail-box">
            <div className="text-data-wrapper">
              {renderPaging(entry)}
              <div className="div-block">
                {
                  user?.email === "is@age.co.jp" &&
                  <div className="div-block-2">
                    <div className="text-block">LINE識別子</div>
                    <div className="text-block-2">
                      <div>
                        <input className="input is-inline is-small" value={entry?.line_user_id}/>
                        <button className="is-danger button is-small" onClick={
                          async (e) => {
                            try {
                              await axios.post("https://app.q-uad.me/api/audience/create.json", {
                                  description: `${entry?.entry_data.name}さん`,
                                  userIDs: [entry?.line_user_id]
                                },
                                {
                                  headers: {
                                    "x-access-token": "dc61e2ef7284eb2bf489ecfe92757cf251af87961d0f450ab98bd37619150866",
                                    "x-firebase-user-id": user?.uid
                                  }
                                });
                              alert("オーディエンスを作成しました");
                            } catch (error) {
                              console.error(error);
                            }
                          }
                        }>オーディエンス作成
                        </button>
                      </div>
                    </div>
                  </div>
                }

                <div className="div-block-2">
                  <div className="text-block">名前</div>
                  <div className="text-block-2">
                    <ruby>
                      {entry?.entry_data.name}
                      <rt>
                        {entry?.entry_data.name_kana}
                      </rt>
                    </ruby>
                  </div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">生年月日・性別</div>
                  <div className="text-block-2">
                    {entry?.entry_data.birthday}・{entry?.entry_data.gender}
                  </div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">大学（キャンパス）</div>
                  <div className="text-block-2">
                    {entry?.entry_data.university}
                    {entry?.entry_data.campus &&
                    <>（{entry?.entry_data.campus}）</>
                    }
                  </div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">学部・学科・学年</div>
                  <div className="text-block-2">{entry?.entry_data.faculty} {entry?.entry_data.grade}</div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">所属サークルなど</div>
                  <div className="text-block-2">{
                    entry?.entry_data.circle || "-"
                  }</div>
                </div>

                <div className="div-block-2">
                  <div className="text-block">紹介者</div>
                  <div className="text-block-2">
                    {entry?.entry_data.referred_by || "-"}
                  </div>
                </div>

                <div className="div-block-3">
                  <div className="text-block-3"><span className="tag">活動やSNSなど</span></div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: (entry?.entry_data.activity || "記述なし").replace(/\n/g, '<br />')
                      .replace(/(https?:\/\/(?:[a-zA-Z0-9.?@/?_=\-+#%&]+))/g, `<a href="$1" target="_blank" rel="noreferrer">$1</a>`)
                  }}/>
                </div>

                <div className="div-block-3">
                  <div className="text-block-3"><span className="tag">特技・資格など</span></div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: (entry?.entry_data.appeal || "記述なし").replace(/\n/g, '<br />')
                      .replace(/(https?:\/\/(?:[a-zA-Z0-9.?@/?_=\-+#%&]+))/g, `<a href="$1" target="_blank" rel="noreferrer">$1</a>`)
                  }}/>
                </div>


                <div className="div-block-3">
                  <div className="text-block-3"><span className="tag is-danger">エナジードリンクの飲用頻度</span></div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.energy_drink.replace(/\n/g, '<br />')
                  }}/>
                </div>
                <div className="div-block-3">
                  <div className="text-block-3"><span className="tag is-danger">飲用銘柄</span></div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.brand?.replace(/\n/g, '<br />')
                  }}/>
                </div>

                <div className="div-block-3">
                  <div className="text-block-3"><span className="tag is-info">志望理由</span></div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.reason.replace(/\n/g, '<br />')
                  }}/>
                </div>
                <div className="div-block-3">
                  <div className="text-block-3">
                    <span className="tag is-info">大学生になってから最も打ち込んだことは何ですか？</span></div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.pr.replace(/\n/g, '<br />')
                  }}/>
                </div>
                <div className="div-block-3">
                  <div className="text-block-3"><span
                    className="tag is-info">ZONeの魅力と、あなたの周りでZONeがエナジードリンクのシェアNO1になるためのアイデア</span></div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.idea.replace(/\n/g, '<br />')
                  }}/>
                </div>

              </div>
              {renderPaging(entry)}
            </div>
            <div className="image-wrapper">
              <a href={images[2]} data-fancybox="gallery">
                <img
                  src={images[2]}
                  loading="lazy"
                  sizes="(max-width: 767px) 96vw, (max-width: 991px) 291.65625px, 380px" alt=""/>
              </a>
              {entry?.entry_data.picture_memo &&
              <p>
                <span className="tag is-small">写真の補足</span>
                {entry?.entry_data.picture_memo}
              </p>
              }
              {
                ["mynavi", "hakuhodo"].includes(getHyokakey(user?.email)) &&
               <>
                 <hr/>
                 <img src={evalImage} alt="評価のイメージ" style={{width: "80%"}}/>
                 <hr/>
                 <div>
                   <h5 className="has-text-weight-bold">(1) 経験・スキル</h5>
                   <p className="help">学内外での活動を通じて、ＺＳＢで生きる経験やスキル有しているか？<br/>※集団活動の経験、説得や交渉力、折衝した経験、その他特筆すべき資格やスキルなど</p>
                   <Rating name="rating1" onChange={async (event, value) => {
                     await changeRating(value, 1);
                   }} size="large" max={10}
                           value={
                             (entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[0]) || 0
                           }
                   />
                   <span>
                  {(entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[0]) || 0}/10
              </span>
                 </div>
                 <div>
                   <h5 className="has-text-weight-bold">(2) 素質</h5>
                   <p className="help">
                     まわりの人を惹きつける素質があるか？<br/>
                     ※外向的である・学内外の交友関係が豊富である、人を魅了する才能や能力、強烈な個性があるなど
                   </p>

                   <Rating name="rating2" onChange={async (event, value) => {
                     await changeRating(value, 2);
                   }} size="large" max={10}
                           value={
                             (entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[1]) || 0
                           }
                   />
                   <span>
                  {(entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[1]) || 0}/10
              </span>
                 </div>
                 <div>
                   <h5 className="has-text-weight-bold">(3) 意欲</h5>
                   <p className="help">
                     志望動機が明確で、高い意欲や目的意識を持って応募しているか？<br/>
                     ※積極性・挑戦心・へこたれない強さ・自走する力・活動する覚悟
                   </p>
                   <Rating name="rating3" onChange={async (event, value) => {
                     await changeRating(value, 3);
                   }} size="large" max={10}
                           value={
                             (entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[2]) || 0
                           }
                   />
                   <span>
                  {(entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[2]) || 0}/10
              </span>
                 </div>
                 <div>
                   <h5 className="has-text-weight-bold">(4) ZONeブランド理解・興味関心</h5>
                   <p className="help">
                     ZONeブランドについて十分な理解や、強い興味関心があるか？
                   </p>
                   <Rating name="rating4" onChange={async (event, value) => {
                     await changeRating(value, 4);
                   }} size="large" max={10}
                           value={
                             (entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[3]) || 0
                           }
                   />
                   <span>
                  {(entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[3]) || 0}/10
              </span>
                 </div>
                 <div>
                   <h5 className="has-text-weight-bold">(5) 考え方</h5>
                   <p className="help">
                     ビジネス視点・課題意識を持っている。 解決のプロセスを組み立てる思考力や、経験があるか？ 既存の手段に囚われず、創造的な発想ができるか？
                   </p>
                   <Rating name="rating5" onChange={async (event, value) => {
                     await changeRating(value, 5);
                   }} size="large" max={10}
                           value={
                             (entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[4]) || 0
                           }
                   />
                   <span>
                  {(entry?.meta_data?.rating && entry?.meta_data.rating[getHyokakey(user?.email)].point[4]) || 0}/10
              </span>
                 </div>
                 <div className="comment-wrapper">
                   <h5 className="has-text-weight-bold">(6) 記入者</h5>
                   <TextField
                     label="by"
                     variant="outlined"
                     placeholder="記入者"
                     id="author"
                     name="author"
                     defaultValue={entry?.meta_data?.rating[getHyokakey(user?.email)].author || ""}
                     onBlur={ async(event) => {
                       await changeRatingAuthor(event.target.value);
                     }}
                   />
                   <h5 className="has-text-weight-bold">(7) フリーコメント</h5>

                   <TextField
                     id="new-comment"
                     label="コメントを記入"
                     multiline
                     rows={4}
                     variant="outlined"
                     style={{width: "100%"}}
                     onFocus={(e) => {
                       console.log(e);
                       setKeyOp(false);
                     }}
                     onBlur={(e) => {
                       setKeyOp(true);
                     }}
                   />
                   <br/><br/>
                   <Button className="is-warning button is-fullwidth" onClick={
                     async (e) => {
                       const text: any = document.getElementById("new-comment");
                       const author: any = document.getElementById("author");
                       if (text.value) {
                         const metaData = entry?.meta_data ?? {
                           "rating": {"mynavi": {"author":"" ,"point":[0, 0, 0, 0, 0]}, "hakuhodo": {"author":"" ,"point":[0, 0, 0, 0, 0]}},
                           "comments": []
                         };
                         if (metaData.comments) {
                         } else {
                           metaData.comments = [];
                         }
                         metaData.comments?.unshift({
                           "author": author.value || user?.email,
                           "at": (new Date()).toLocaleString(),
                           "body": text.value
                         });
                         entry.meta_data = metaData;
                         setEntry(Object.assign({}, entry));
                         await putMetaData(entry?.id, metaData);
                         text.value = "";
                       }
                     }
                   }> 保存 </Button>
                   {
                     entry?.meta_data?.comments?.map((val: any) => (
                       <Comment key={val.at} author={val.author} at={val.at} body={val.body}/>))
                   }
                 </div>
               </>
              }
            </div>
          </div>
        </>
      }
    </div>
  );

  function fancybox() {
    try {
      const global: any = window;
      const query: any = global.$('a[data-fancybox]');
      query &&
      query.fancybox({
        loop: true,
        infobar: false,
        buttons: ['close']
      });
    } catch {
    }
  }
}
