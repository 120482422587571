/* eslint-disable react-hooks/exhaustive-deps */
import {User} from "@firebase/auth-types";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useHistory, useLocation} from "react-router-dom";
import dayjs from "dayjs";
import Loading from '../components/Loading';
import './EntryList.scss';
import {Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import queryString from 'query-string';


export default function EntryList({match, user}: { match: any | null, user: User | null }) {
  const page = parseInt(match.params.page, 10);
  const location = useLocation();
  const params: any = queryString.parse(location.search);
  const [loading, setLoading] = useState<boolean>(true);
  const [entries, setEntries] = useState<any[]>([]);
  const [allEntries, setAllEntries] = useState<any[]>([]);
  const [curEntries, setCurEntries] = useState<any[]>([]);
  const [checkFreq, setCheckFreq] = useState<boolean>(params.checkFreq);
  const [checkSort, setCheckSort] = useState<boolean>(params.checkSort);
  const pagingSize = 25;
  const history = useHistory();
  useEffect(() => {
    if (user) {
      fetchList()
    }
  }, [user]);
  useEffect(() => setPage(page), [page]);
  const fetchList = async () => {
    setLoading(true);
    try {
      const {data} = await axios.get("https://app.q-uad.me/api/entry/list.json?col=name,name_kana,university,faculty,grade,energy_drink&hasMeta=1", {
        // const {data} = await axios.get("http://localhost:8765/api/entry/list.json?col=name,university", {
        headers: {
          "x-access-token": "dc61e2ef7284eb2bf489ecfe92757cf251af87961d0f450ab98bd37619150866",
          "x-firebase-user-id": user?.uid
        }
      });
      let tmpEntries = [];
      tmpEntries = data.result.entries;
      setAllEntries(data.result.entries);
      if (params.name) {
        const regex = new RegExp(`${params.name}`, 'g');
        tmpEntries = tmpEntries.filter((entry: any) => regex.test(entry.name) || regex.test(entry.name_kana));
      }
      if (params.university) {
        tmpEntries = tmpEntries.filter((entry: any) => {
          return entry.university === params.university
        })
      }

      if (params.checkFreq) {
        tmpEntries = tmpEntries.filter((entry: any) => {
          return [
            "週5日以上飲む",
            "週3〜4日程度飲む",
            "週1〜2日程度飲む"
          ].includes(entry.energy_drink)
        })
      }

      tmpEntries = tmpEntries.map((entry: any) => {
        if (!entry.meta_data || !entry.meta_data.rating) {
          entry.point = 0;
        } else {
          if (!entry.meta_data.rating[getHyokakey(user?.email)] || !entry.meta_data.rating[getHyokakey(user?.email)].point) {
            entry.point = 0;
          } else {
            entry.point = entry.meta_data.rating[getHyokakey(user?.email)].point.reduce((s: number, e: number) => s + e, 0);
          }

          if(getHyokakey(user?.email) === "suntory"){
            entry.mynavi_point = entry.meta_data.rating["mynavi"].point.reduce((s: number, e: number) => s + e, 0);
            entry.hakuhodo_point =  entry.meta_data.rating["hakuhodo"].point.reduce((s: number, e: number) => s + e, 0);
            entry.point = entry.mynavi_point + entry.hakuhodo_point;
          }

        }
        return entry;
      });

      if(params.checkSort) {
        tmpEntries = tmpEntries.sort((a: any, b: any) => {
            return (a.point > b.point) ? -1 : 1;
          }
        );
      }



      setEntries(tmpEntries);
      setCurEntries(tmpEntries.slice(pagingSize * (page - 1), pagingSize * page));

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const getHyokakey = (email: string | null | undefined) => {
    if (email === "mynavi@zsb-recruiting.com") {
      return "mynavi";
    } else if (email === "hakuhodo@zsb-recruiting.com") {
      return "hakuhodo";
    } else if (email === "suntory@zsb-recruiting.com") {
      return "suntory";
    }
    return "";
  };

  const setPage = (num: number) => {
    setCurEntries(entries.slice(pagingSize * (num - 1), pagingSize * num));
  }

  // const createAudienceAll = async (target: any) => {
  //   try {
  //     await axios.post("https://app.q-uad.me/api/audience/create.json", {
  //         //     const {data} = await axios.post("http://localhost:8765/api/audience/create.json", {
  //         description: "AllEntryUsers",
  //         userIDs: entries.map((v) => v.line_user_id)
  //       },
  //       {
  //         headers: {
  //           "x-access-token": "dc61e2ef7284eb2bf489ecfe92757cf251af87961d0f450ab98bd37619150866",
  //           "x-firebase-user-id": user?.uid
  //         }
  //       });
  //     alert("オーディエンスを作成しました");
  //     target.disabled = true;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const renderPagingContainer = (page: number, entries: any) => {
    return (
      <div className="paging-container">
        {
          page !== 1 &&
          <Link to={`/list/${page - 1}?${queryString.stringify(queryString.parse(location.search))}`}
                className="is-primary button is-small"> « </Link>
        }
        <p style={{"padding": "10"}}>
          {`${entries.length}件中 ${pagingSize * (page - 1) + 1}-${pagingSize * (page - 1) + curEntries.length}件を表示中`}
        </p>
        {
          pagingSize * (page - 1) + curEntries.length < entries.length &&
          <Link to={`/list/${page + 1}?${queryString.stringify(queryString.parse(location.search))}`}
                className="is-primary button is-small"> » </Link>
        }
      </div>
    )
  }
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="title">エントリーシート一覧</h1>
          {loading ? <Loading width={48}/> :
            /**
             * check size of entries
             * valid or redirect.
             */
            <>
              <div style={{"padding": "20px 0"}}>
                <form method="get" style={{"display": "flex", "flexDirection": "row", "alignItems": "center"}}>
                  <TextField id="name" name="name" label="名前" type="search" variant="outlined"
                             defaultValue={params.name} style={{"marginRight": "1em"}}/>
                  <div className="select" style={{marginRight:"16px"}}>
                    <select id="university" name="university" defaultValue={params.university}>
                      <option value="">大学名</option>
                      {
                        Array.from(new Set( allEntries.map( (entry:any) => {
                          return entry.university;
                        })) ).sort().map((value: string) => (
                          <option value={value} key={value}>{value}</option>
                        ))
                      }
                    </select>
                  </div>


                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkFreq"
                        color="primary"
                        value="1"
                        checked={checkFreq}
                        onChange={() => {
                          setCheckFreq(!checkFreq);
                        }}
                      />
                    }
                    label="飲用頻度が週1以上"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkSort"
                        color="primary"
                        value="1"
                        checked={checkSort}
                        onChange={() => {
                          setCheckSort(!checkSort);
                        }}
                      />
                    }
                    label="評価順に並び替え"
                  />
                  <button className="button is-warning" type="submit">再表示</button>
                </form>
              </div>
              {renderPagingContainer(page, entries)}
              <div className="table-container">
                <table id="entryList" className="table is-fullwidth is-striped">
                  <thead>
                  <tr>
                    <th>評価</th>
                    <th>大学</th>
                    <th>学部学年</th>
                    <th>名前</th>
                    <th>飲用頻度</th>
                    <th>エントリー日時</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    curEntries.map((entry, index) => {
                      return (
                        <tr key={entry.id}>
                          <td>{
                            (getHyokakey(user?.email) === "suntory") ?
                              <>
                              {entry.point}({entry.mynavi_point},{entry.hakuhodo_point})
                              </>
                              :
                              entry.point
                          }</td>
                          <td>{entry.university}</td>
                          <td>{entry.faculty} {entry.grade}</td>
                          <td>
                            <button className="is-text button" onClick={() => {
                              history.push(`/detail/${entry.id}`, {entries})
                            }}>
                              {entry.name}
                            </button>
                          </td>
                          <td>
                            <span className="tag">
                            {entry.energy_drink}
                            </span>
                          </td>
                          <td>{dayjs(entry.created).format(`YYYY年MM月DD日 HH:mm`)}</td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>
            </>
          }
          {renderPagingContainer(page, entries)}
        </div>
      </section>
    </>
  );
}
